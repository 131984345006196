.wrapper
{
    display: flex;
    justify-content: center;

    width: 100%;
    height: calc(100% - .3rem);
}

.content
{
    width: calc(100% - 2rem);
    height: calc(100% - .3rem);
}

.checklistsWrapper
{
    display: flex;
    max-height: calc(100% - 7.5rem);
    height: calc(100% - 7.5rem);
}

.checklistWrapper
{
    display: flex;
    flex-direction: column;

    margin: .2rem;
}

.checklistHeader
{
    font-size: 1.2rem;
    margin: .5rem 0;

    border-block-end: 1px solid grey;
}

.itemContainer
{
    background-color: whitesmoke;
    border-radius: 5px;
}

.noFound
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.noFoundText
{
    color: gray;
    font-size: 2rem;
    margin: .5rem;
}

.item 
{
    display: flex;
    align-items: center;

    padding-inline-start: .5rem;

    height: 2.5rem;

    background-color: none;
    cursor: pointer;

    transition: background-color .1s ease-in-out;

    border-inline-start: 2px solid #23527c;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.item:hover
{
    background-color: #23527c33;
    
    transition: background-color .1s ease-in-out;
}