.slidecontainer {
  width: 100%; /* Width of the outside container */
  display: flex;
  justify-content: center;
}

/* The slider itself */
.slider {
  appearance: none;
  width: 80%;
  height: 25px;
  background-color: #EEEEEE;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  margin-block-end: 0.3rem;

  border-radius: 4px;
  overflow: hidden;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
.slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background-color: #212121;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background-color: #212121;
  cursor: pointer;
}

.slider{
  display: inline;
}

.label {
  text-align: center;
  padding-block-start: 0rem;
  margin-block-start:0rem;
  font-weight: bold;
}
.sideLabel{
  margin-block-start:.25rem;
  width: 3.5rem;
  text-align: center;
}

.text{
  padding-inline-end: .3rem;
  padding-inline-start: .3rem;
}

.arrow{
  cursor: pointer;
}